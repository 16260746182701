import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    useTheme,
    TextField,
    Box
} from '@mui/material';
import {
    Schedule as ScheduleIcon,
    Business as BusinessIcon,
    Support as SupportIcon
} from '@mui/icons-material';

const ContactDialog = ({ open, onClose, serviceTitle }) => {
    const theme = useTheme();

    const options = [
        {
            label: 'Schedule Consultation',
            value: 'schedule',
            icon: <ScheduleIcon />,
            description: 'Book a personalized consultation with our AI experts',
            emailSubject: 'Consultation Request',
            emailBody: `Hi DevCraft team,\n\nI would like to schedule a consultation regarding ${serviceTitle}.\n\nBest time to reach me:\n\nProject details:\n\nBest regards,`
        },
        {
            label: 'Contact Sales',
            value: 'sales',
            icon: <BusinessIcon />,
            description: 'Discuss pricing and custom solutions for your business',
            emailSubject: 'Sales Inquiry',
            emailBody: `Hi DevCraft team,\n\nI'm interested in learning more about ${serviceTitle}.\n\nCompany size:\nBudget range:\nTimeline:\nSpecific requirements:\n\nBest regards,`
        },
        {
            label: 'Technical Support',
            value: 'support',
            icon: <SupportIcon />,
            description: 'Get technical assistance and implementation support',
            emailSubject: 'Technical Support',
            emailBody: `Hi DevCraft team,\n\nI need technical support for ${serviceTitle}.\n\nIssue description:\nSteps to reproduce:\nCurrent behavior:\nExpected behavior:\n\nBest regards,`
        }
    ];

    const handleOptionClick = (option) => {
        const mailtoLink = `mailto:contact@devcraft.co.in?subject=${encodeURIComponent(option.emailSubject + ' - ' + serviceTitle)}&body=${encodeURIComponent(option.emailBody)}`;
        window.location.href = mailtoLink;
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                sx: {
                    borderRadius: 2,
                    bgcolor: 'background.paper',
                }
            }}
        >
            <DialogTitle sx={{
                borderBottom: 1,
                borderColor: 'divider',
                pb: 2,
                background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
                color: 'white'
            }}>
                <Typography variant="h5" component="div" fontWeight="bold">
                    How would you like to get started?
                </Typography>
                <Typography variant="subtitle1" sx={{ mt: 1, opacity: 0.9 }}>
                    Select your preferred way to connect with us
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ mt: 2 }}>
                <List sx={{ py: 0 }}>
                    {options.map((option) => (
                        <ListItem
                            key={option.value}
                            disablePadding
                            sx={{ mb: 2 }}
                        >
                            <ListItemButton
                                onClick={() => handleOptionClick(option)}
                                sx={{
                                    borderRadius: 2,
                                    border: 1,
                                    borderColor: 'divider',
                                    '&:hover': {
                                        bgcolor: theme.palette.primary.light + '20',
                                        borderColor: theme.palette.primary.main,
                                    }
                                }}
                            >
                                <ListItemIcon sx={{
                                    color: theme.palette.primary.main,
                                    bgcolor: theme.palette.primary.light + '20',
                                    p: 1,
                                    borderRadius: 2,
                                    mr: 2
                                }}>
                                    {option.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={
                                        <Typography variant="h6" sx={{ fontWeight: 'medium', mb: 0.5 }}>
                                            {option.label}
                                        </Typography>
                                    }
                                    secondary={
                                        <Typography variant="body2" color="text.secondary">
                                            {option.description}
                                        </Typography>
                                    }
                                />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </DialogContent>
            <DialogActions sx={{
                px: 3,
                py: 2,
                borderTop: 1,
                borderColor: 'divider',
                bgcolor: 'background.default'
            }}>
                <Button
                    onClick={onClose}
                    variant="outlined"
                    color="primary"
                    sx={{
                        borderRadius: 2,
                        textTransform: 'none',
                        px: 4
                    }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ContactDialog; 