import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Container,
    Grid,
    Link,
    Typography,
    Divider,
} from '@mui/material';

const Footer = () => {
    return (
        <Box
            sx={{
                bgcolor: 'background.paper',
                py: 2,
                mt: 'auto',
            }}
        >
            <Container maxWidth="lg">
                {/* <Grid container spacing={4}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Company
                        </Typography>
                        <Link component={RouterLink} to="/about" color="text.secondary" display="block">
                            About
                        </Link>
                        <Link component={RouterLink} to="/terms" color="text.secondary" display="block">
                            Terms
                        </Link>
                        <Link component={RouterLink} to="/privacy" color="text.secondary" display="block">
                            Privacy
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Services
                        </Typography>
                        <Link href="#" color="text.secondary" display="block">
                            AI Integration
                        </Link>
                        <Link href="#" color="text.secondary" display="block">
                            Custom Agents
                        </Link>
                        <Link href="#" color="text.secondary" display="block">
                            Consulting
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Contact
                        </Typography>
                        <Typography color="text.secondary">
                            <Link href="mailto:contact@devcraft.co.in" color="primary">
                                contact@devcraft.co.in
                            </Link>
                        </Typography>
                    </Grid>
                </Grid> */}
                <Divider sx={{ mt: 1, mb: 4 }} />
                <Typography variant="body2" color="text.secondary" align="center">
                    © 2025 devcraft. All rights reserved.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 3, mt: 2 }}>
                    <Link component={RouterLink} to="/privacy" sx={{ textDecoration: 'none', color: 'primary.main' }}>
                        Privacy Policy
                    </Link>
                    <Link component={RouterLink} to="/terms" sx={{ textDecoration: 'none', color: 'primary.main' }}>
                        Terms and Conditions
                    </Link>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;