import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Card,
  CardContent,
  Button,
  useTheme,
  Link,
  Chip
} from '@mui/material';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { Link as RouterLink } from 'react-router-dom';
import ContactDialog from './ContactDialog';

const About = () => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedService, setSelectedService] = useState('');

  const handleGetStarted = (serviceTitle) => {
    setSelectedService(serviceTitle);
    setDialogOpen(true);
  };

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', minHeight: '100vh' }}>
      {/* Particles Background */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      >
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            background: {
              color: {
                value: theme.palette.primary.main,
              },
            },
            particles: {
              number: { value: 80, density: { enable: true, value_area: 800 } },
              color: { value: "#ffffff" },
              links: { enable: true, color: "#ffffff", opacity: 0.4 },
              move: { enable: true, speed: 2 },
            },
          }}
        />
      </Box>

      {/* Hero Section */}
      <Box sx={{
        position: 'relative',
        color: 'white',
        pt: 8,
        pb: 6,
        textAlign: 'center',
        minHeight: '60vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            sx={{
              mb: 4,
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #FFF 30%, #E0E0E0 90%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
            About Us
          </Typography>
          <Typography variant="h5" sx={{ mb: 4, color: 'rgba(255, 255, 255, 0.9)' }}>
            AI-driven innovation to enhance business efficiency and decision-making.
          </Typography>
        </Container>
      </Box>

      {/* About Section */}
      <Box sx={{
        bgcolor: 'background.paper',
        py: 8,
        borderRadius: '40px 40px 0 0',
        marginTop: -4,
        position: 'relative',
        zIndex: 1,
      }}>
        <Container maxWidth="md">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={12}>
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: 'bold',
                  background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  mb: 4
                }}>
                Empowering Businesses with AI
              </Typography>
              <Typography sx={{ mb: 3, fontSize: '1.1rem', color: 'text.secondary' }}>
                Welcome to <strong>Devcraft</strong>, your trusted partner in AI-driven solutions. We specialize in
                <strong> AI-powered applications, automation tools, and intelligent agents</strong> to help businesses
                streamline operations, enhance decision-making, and drive growth.
              </Typography>
              <Typography sx={{ fontSize: '1.1rem', color: 'text.secondary' }}>
                With expertise in <strong>full-stack development</strong> and AI integration, we offer tailored solutions
                for AI models, chatbots, automation, and data-driven insights.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Why Choose Us Section */}
      <Box sx={{ bgcolor: 'background.default', py: 8 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            align="center"
            sx={{
              mb: 6,
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
            Why Work With Us?
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {[
              { title: "Expertise in AI & Automation", description: "Cutting-edge AI solutions for real-world applications." },
              { title: "Customized AI Solutions", description: "AI tailored for specific industry challenges." },
              { title: "Seamless Integration", description: "Works with existing systems (CRM, ERP, APIs)." },
              { title: "End-to-End Development", description: "From ideation to deployment, we handle everything." },
              { title: "Client-Centric Approach", description: "Personalized support, fast response, and flexible engagement." }
            ].map((feature, index) => (
              <Grid item key={index} xs={12} sm={6} md={4}>
                <Card sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  p: 3,
                  borderRadius: 4,
                  boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 16px 40px rgba(0,0,0,0.12)',
                  },
                }}>
                  <CardContent>
                    <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main }}>
                      {feature.title}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">{feature.description}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Technologies Section */}
      <Box sx={{ bgcolor: 'background.paper', py: 8 }}>
        <Container maxWidth="lg">
          <Typography
            variant="h4"
            align="center"
            sx={{
              mb: 6,
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
            Technologies We Work With
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {[
              { category: "AI/ML", tools: "TensorFlow.js, OpenAI, GPT, LangChain" },
              { category: "Cloud", tools: "Azure AI, AWS, Google Cloud AI" },
              { category: "Web Development", tools: "React, Node.js, Express.js, Next.js" },
              { category: "Automation", tools: "Python, RPA, No-Code AI Workflows" }
            ].map((tech, index) => (
              <Grid item key={index} xs={12} sm={6} md={3}>
                <Card sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  p: 3,
                  borderRadius: 4,
                  boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                  transition: 'all 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-8px)',
                    boxShadow: '0 16px 40px rgba(0,0,0,0.12)',
                  },
                }}>
                  <CardContent>
                    <Chip
                      label={tech.category}
                      sx={{
                        mb: 2,
                        bgcolor: theme.palette.primary.light + '20',
                        color: theme.palette.primary.main,
                        fontWeight: 'bold'
                      }}
                    />
                    <Typography variant="body1" color="text.secondary">{tech.tools}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>

      {/* Call to Action Section */}
      <Box sx={{
        bgcolor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        py: 8,
        background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
      }}>
        <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
          Let's Build Something Amazing
        </Typography>
        <Typography sx={{ mb: 4, opacity: 0.9 }}>
          AI is transforming businesses—let's explore how it can work for you!
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          sx={{
            borderRadius: 2,
            textTransform: 'none',
            fontSize: '1.1rem',
            py: 1.5,
            px: 4,
            boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
          }}
          onClick={() => handleGetStarted('General Inquiry')}
        >
          Contact Us
        </Button>
      </Box>

      <ContactDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        serviceTitle={selectedService}
      />
    </Box>
  );
};

export default About;
