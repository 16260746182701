import React from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  useTheme,
  Grid,
  Divider
} from '@mui/material';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

export default function Terms() {
  const theme = useTheme();

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', minHeight: '100vh' }}>
      {/* Particles Background */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      >
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            background: {
              color: {
                value: theme.palette.primary.main,
              },
            },
            particles: {
              number: { value: 80, density: { enable: true, value_area: 800 } },
              color: { value: "#ffffff" },
              links: { enable: true, color: "#ffffff", opacity: 0.4 },
              move: { enable: true, speed: 2 },
            },
          }}
        />
      </Box>

      {/* Hero Section */}
      <Box sx={{
        position: 'relative',
        color: 'white',
        pt: 8,
        pb: 6,
        textAlign: 'center',
        minHeight: '30vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            sx={{
              mb: 4,
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #FFF 30%, #E0E0E0 90%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
            Terms and Conditions
          </Typography>
        </Container>
      </Box>

      {/* Content Section */}
      <Box sx={{
        bgcolor: 'background.paper',
        py: 8,
        borderRadius: '40px 40px 0 0',
        marginTop: -4,
        position: 'relative',
        zIndex: 1,
      }}>
        <Container maxWidth="md">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography paragraph color="text.secondary">
                    Welcome to <Typography component="span" color="primary.main" fontWeight="bold">devcraft</Typography>.
                    These Terms and Conditions ("Terms") govern your use of this website and the AI development services
                    provided by me ("Freelancer", "I", "Me"). By accessing this Website or engaging with my services,
                    you agree to comply with these Terms.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    1. Services Offered
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    I provide AI development services, including but not limited to AI agents, automation, and AI consulting.
                    Service details will be outlined in separate project agreements or invoices.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    2. Client Responsibilities
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    Clients must provide all necessary project details, including data, access credentials (if applicable),
                    and clear requirements. Any delays caused due to the client's failure to provide required information
                    are not my responsibility.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    3. Payments & Pricing
                  </Typography>
                  <Box component="ul" sx={{ color: 'text.secondary', pl: 2 }}>
                    <li>Payments are accepted in USD (for international clients) and INR (for Indian clients) via Stripe, Razorpay, or PayPal.</li>
                    <li>Services are charged on a prepaid or milestone basis (as agreed per project).</li>
                    <li>No work will commence until an upfront payment or agreed milestone payment is received.</li>
                    <li>Payment processing fees charged by third-party gateways are the responsibility of the client.</li>
                  </Box>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    4. Refunds & Cancellations
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    - **No refunds** will be issued once work has started, unless explicitly stated in a project agreement.
                    <br />
                    - If the project is canceled after the initial payment, refunds (if any) will be based on the work completed.
                    <br />
                    - Any chargeback or dispute filed with a payment gateway without prior communication will result in termination of services.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    5. Intellectual Property
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    - Upon full payment, the client **owns the final deliverables** unless otherwise agreed.
                    <br />
                    - I reserve the right to display project work (except confidential projects) in my portfolio.
                    <br />
                    - Clients may not resell or redistribute the AI models, scripts, or solutions provided without prior consent.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    6. Confidentiality
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    - I respect the confidentiality of client data and will not share it with third parties without consent.
                    <br />
                    - Clients are responsible for ensuring any sensitive data shared complies with applicable legal requirements.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    7. Limitation of Liability
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    - I provide AI development services "as-is" without any guarantees of performance, accuracy, or suitability for specific business needs.
                    <br />
                    - I am **not liable for direct, indirect, or incidental damages** resulting from the use of delivered AI solutions.
                    <br />
                    - Any technical or security risks arising from client-side implementation are the responsibility of the client.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    8. Third-Party Integrations
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    - Some AI services may rely on third-party APIs (e.g., OpenAI, Google Cloud, AWS). I am not responsible for their uptime, pricing changes, or data policies.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    9. Modifications to Terms
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    I may update these Terms from time to time. Any changes will be posted on this page, and the last updated date will be modified accordingly. Clients are encouraged to review these Terms periodically.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    10. Contact Information
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    If you have any questions or concerns about these Terms and Conditions, you can contact me at:
                  </Typography>
                  <Typography
                    component="a"
                    href="mailto:contact@devcraft.co.in"
                    sx={{
                      color: theme.palette.primary.main,
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      display: 'block',
                      mt: 2,
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    📧 contact@devcraft.co.in
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
