import React from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  useTheme,
  Grid,
  Divider
} from '@mui/material';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';

export default function Privacy() {
  const theme = useTheme();

  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <Box sx={{ position: 'relative', width: '100%', minHeight: '100vh' }}>
      {/* Particles Background */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1,
        }}
      >
        <Particles
          id="tsparticles"
          init={particlesInit}
          options={{
            background: {
              color: {
                value: theme.palette.primary.main,
              },
            },
            particles: {
              number: { value: 80, density: { enable: true, value_area: 800 } },
              color: { value: "#ffffff" },
              links: { enable: true, color: "#ffffff", opacity: 0.4 },
              move: { enable: true, speed: 2 },
            },
          }}
        />
      </Box>

      {/* Hero Section */}
      <Box sx={{
        position: 'relative',
        color: 'white',
        pt: 8,
        pb: 6,
        textAlign: 'center',
        minHeight: '30vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <Container maxWidth="sm">
          <Typography
            component="h1"
            variant="h2"
            sx={{
              mb: 4,
              fontWeight: 'bold',
              background: 'linear-gradient(45deg, #FFF 30%, #E0E0E0 90%)',
              backgroundClip: 'text',
              textFillColor: 'transparent',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}>
            Privacy Policy
          </Typography>
          <Typography variant="h6" sx={{ mb: 2, color: 'rgba(255, 255, 255, 0.9)' }}>
            Last Updated: 16-Feb-2025
          </Typography>
        </Container>
      </Box>

      {/* Content Section */}
      <Box sx={{
        bgcolor: 'background.paper',
        py: 8,
        borderRadius: '40px 40px 0 0',
        marginTop: -4,
        position: 'relative',
        zIndex: 1,
      }}>
        <Container maxWidth="md">
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    1. Introduction
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    Welcome to <strong>devcraft</strong> ("Website", "we", "our", or "us"). This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you visit our website, engage with our services, or communicate with us.
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    By accessing or using our Website, you agree to the terms outlined in this Privacy Policy. If you do not agree, please refrain from using our Website and services.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    2. Information We Collect
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    As a freelancer, I collect minimal information necessary to provide AI development services. The types of data collected include:
                  </Typography>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 3 }}>
                    A. Information You Provide Voluntarily
                  </Typography>
                  <Box component="ul" sx={{ color: 'text.secondary' }}>
                    <li>When you contact me via email, contact forms, or social media, I may collect your <strong>name, email address, phone number, and project details</strong>.</li>
                    <li>If you purchase a service, payment-related information is processed securely by third-party payment processors (e.g., <strong>Stripe, Razorpay, PayPal</strong>).</li>
                  </Box>
                  <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 3 }}>
                    B. Automatically Collected Information
                  </Typography>
                  <Box component="ul" sx={{ color: 'text.secondary' }}>
                    <li>I do <strong>not use tracking technologies, cookies, or analytics tools</strong> to collect personal data from visitors.</li>
                  </Box>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    3. How We Use Your Information
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    I use the collected information solely for the following purposes:
                  </Typography>
                  <Box component="ul" sx={{ color: 'text.secondary' }}>
                    <li>Responding to inquiries and providing AI development services.</li>
                    <li>Processing payments through third-party providers.</li>
                    <li>Communicating about project updates and service improvements.</li>
                    <li>Fulfilling legal, tax, and accounting obligations.</li>
                  </Box>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    4. Payment Processing & Security
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    Payments for services are processed via <strong>third-party payment gateways</strong> such as <strong>Stripe, Razorpay, or PayPal</strong>. These platforms handle all payment transactions securely.
                  </Typography>
                  <Box component="ul" sx={{ color: 'text.secondary' }}>
                    <li>I <strong>do not store any credit/debit card details</strong> on my servers.</li>
                    <li>Users should review the privacy policies of the selected payment gateway before making a payment.</li>
                  </Box>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    5. Data Sharing & Disclosure
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    I do <strong>not sell, rent, or trade</strong> any personal data. However, I may disclose your information in the following cases:
                  </Typography>
                  <Box component="ul" sx={{ color: 'text.secondary' }}>
                    <li><strong>Legal Compliance</strong>: If required by law, government authorities, or to protect my rights.</li>
                    <li><strong>Business Transfers</strong>: If I transition from freelance work to a registered company, certain data may be transferred to the new entity.</li>
                  </Box>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    6. Third-Party Links
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    This Website may contain links to external websites. I am <strong>not responsible for the privacy practices of third-party websites</strong>. Users should review the respective privacy policies before sharing any data.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    7. Data Retention
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    Contact information and project details are retained only for as long as necessary to fulfill business obligations.
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    Financial transaction records are stored securely for <strong>tax and compliance purposes</strong> as per Indian regulations.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    8. Your Rights & Choices
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    You may request access to the personal data I hold and request corrections if necessary.
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    You can request that I stop sending emails or delete your contact details by reaching out via email.
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    If you wish to have your information removed, you can email me at <strong>contact@devcraft.co.in</strong>.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    9. Changes to This Privacy Policy
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    I may update this Privacy Policy periodically. Any changes will be posted on this page with the revised "Last Updated" date. Users are encouraged to review this policy from time to time.
                  </Typography>
                </CardContent>
              </Card>

              <Card sx={{
                borderRadius: 4,
                boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                mb: 4
              }}>
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="h5" gutterBottom sx={{
                    fontWeight: 'bold',
                    color: theme.palette.primary.main,
                    mb: 3
                  }}>
                    10. Contact Information
                  </Typography>
                  <Typography paragraph color="text.secondary">
                    For questions, concerns, or requests related to this Privacy Policy, please contact:
                  </Typography>
                  <Typography
                    component="a"
                    href="mailto:contact@devcraft.co.in"
                    sx={{
                      color: theme.palette.primary.main,
                      textDecoration: 'none',
                      fontWeight: 'bold',
                      display: 'block',
                      mt: 2,
                      '&:hover': {
                        textDecoration: 'underline'
                      }
                    }}
                  >
                    📧 contact@devcraft.co.in
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
