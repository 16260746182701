import React, { useState } from 'react';
import {
    Container,
    Typography,
    Box,
    Grid,
    Card,
    CardContent,
    Button,
    useTheme,
    Link,
    Chip
} from '@mui/material';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { Link as RouterLink } from 'react-router-dom';
import ContactDialog from './ContactDialog';

const Home = () => {
    const theme = useTheme();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedService, setSelectedService] = useState('');

    const handleGetStarted = (serviceTitle) => {
        setSelectedService(serviceTitle);
        setDialogOpen(true);
    };

    const particlesInit = async (main) => {
        await loadFull(main);
    };

    const services = [
        {
            title: 'Custom AI Agents',
            subtitle: 'starting from',
            description: 'Tailored AI agents designed to automate tasks and enhance operations',
            price: '$19/month',
            features: ['1 Custom AI Agent', 'Basic Integration', 'Email Support', 'Basic Analytics']
        },
        {
            title: 'Professional',
            subtitle: 'starting from',
            description: 'Advanced AI solutions for growing businesses',
            price: '$49/month',
            features: ['3 Custom AI Agents', 'Advanced Integration', 'Priority Support', 'Monthly Consultation', 'Advanced Analytics', 'API Access']
        },
        {
            title: 'Enterprise',
            subtitle: 'starting from',
            description: 'Full-scale AI integration for large organizations',
            price: 'Custom',
            features: ['Unlimited AI Agents', 'Full System Integration', '24/7 Support', 'Weekly Consultation', 'Custom Analytics', 'Dedicated API Access', 'SLA Guarantee']
        },
        {
            title: 'AI Development',
            subtitle: 'one-time',
            description: 'Custom AI model development and training',
            price: 'Custom',
            features: ['Custom Model Development', 'Data Training', 'Model Optimization', 'Integration Support', 'Documentation', 'Training Sessions']
        },
        {
            title: 'AI Consulting',
            subtitle: 'per hour',
            description: 'Expert guidance for AI implementation',
            price: '$150/hour',
            features: ['Strategy Planning', 'Technology Selection', 'Implementation Roadmap', 'Best Practices', 'Risk Assessment', 'ROI Analysis']
        },
        {
            title: 'AI Maintenance',
            subtitle: 'monthly',
            description: 'Ongoing support and optimization for AI systems',
            price: '$99/month',
            features: ['Regular Updates', 'Performance Monitoring', 'System Optimization', 'Technical Support', 'Security Updates', 'Backup & Recovery']
        }
    ];

    return (
        <Box sx={{ width: '100%', height: '100%' }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: -1, // Ensures it's behind everything
                }}
            >
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    options={{
                        background: {
                            color: {
                                value: theme.palette.primary.main,
                            },
                        },
                        particles: {
                            number: {
                                value: 80,
                                density: {
                                    enable: true,
                                    value_area: 800,
                                },
                            },
                            color: {
                                value: "#ffffff",
                            },
                            links: {
                                enable: true,
                                color: "#ffffff",
                                opacity: 0.4,
                            },
                            move: {
                                enable: true,
                                speed: 2,
                            },
                        },
                    }}
                />
            </Box>

            {/* Hero Section */}
            <Box
                sx={{
                    position: 'relative',
                    color: 'white',
                    pt: 8,
                    pb: 6,
                    textAlign: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    minHeight: '60vh',
                }}
            >
                <Container maxWidth="sm">
                    <Typography
                        component="h1"
                        variant="h2"
                        sx={{
                            mb: 4,
                            fontWeight: 'bold',
                            background: 'linear-gradient(45deg, #FFF 30%, #E0E0E0 90%)',
                            backgroundClip: 'text',
                            textFillColor: 'transparent',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        Transform Your Business with AI
                    </Typography>
                    <Typography variant="h5" sx={{ mb: 4, color: 'rgba(255, 255, 255, 0.9)' }}>
                        Custom AI solutions and seamless integration services for modern businesses
                    </Typography>
                </Container>
            </Box>

            {/* Services Section */}
            <Box sx={{
                bgcolor: 'background.paper',
                py: 8,
                borderRadius: '40px 40px 0 0',
                marginTop: -4,
                position: 'relative',
                zIndex: 1,
            }}>
                <Container maxWidth="lg">
                    <Typography
                        variant="h3"
                        align="center"
                        sx={{
                            mb: 6,
                            fontWeight: 'bold',
                            background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
                            backgroundClip: 'text',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        Our Services
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        {services.map((service, index) => (
                            <Grid item key={index} xs={12} sm={6} md={4}>
                                <Card
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        borderRadius: 4,
                                        boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
                                        transition: 'all 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'translateY(-8px)',
                                            boxShadow: '0 16px 40px rgba(0,0,0,0.12)',
                                        },
                                    }}
                                >
                                    <CardContent sx={{ flexGrow: 1, p: 3 }}>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="h2"
                                            sx={{ fontWeight: 'bold' }}
                                        >
                                            {service.title}
                                        </Typography>
                                        <Chip
                                            label={service.subtitle}
                                            size="small"
                                            sx={{
                                                mb: 2,
                                                bgcolor: theme.palette.primary.light + '20',
                                                color: theme.palette.primary.main,
                                            }}
                                        />
                                        <Typography
                                            variant="h4"
                                            color="primary"
                                            sx={{
                                                my: 2,
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {service.price}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                mb: 3,
                                                color: 'text.secondary',
                                            }}
                                        >
                                            {service.description}
                                        </Typography>
                                        {service.features.map((feature, idx) => (
                                            <Typography
                                                key={idx}
                                                sx={{
                                                    mb: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 1,
                                                    color: 'text.primary',
                                                }}
                                            >
                                                <span style={{ color: theme.palette.primary.main }}>✓</span> {feature}
                                            </Typography>
                                        ))}
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{
                                                mt: 3,
                                                borderRadius: 2,
                                                textTransform: 'none',
                                                fontSize: '1.1rem',
                                                py: 1.5,
                                            }}
                                            onClick={() => handleGetStarted(service.title)}
                                        >
                                            Get Started
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Box>

            <Box
                sx={{
                    bgcolor: theme.palette.primary.main,
                    color: 'white',
                    textAlign: 'center',
                    py: 8,
                    background: 'linear-gradient(45deg, #1976d2 30%, #42a5f5 90%)',
                }}
            >
                <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
                    Let's Build Something Amazing
                </Typography>
                <Typography sx={{ mb: 4, opacity: 0.9 }}>
                    AI is transforming businesses—let's explore how it can work for you!
                </Typography>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{
                        borderRadius: 2,
                        textTransform: 'none',
                        fontSize: '1.1rem',
                        py: 1.5,
                        px: 4,
                        boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
                    }}
                    onClick={() => handleGetStarted('General Inquiry')}
                >
                    Contact Us
                </Button>
            </Box>

            <ContactDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                serviceTitle={selectedService}
            />
        </Box>
    );
};

export default Home;
